const en = {
  translations: {
    header: {
      title: 'THE FACTORY IS OPEN!',
      description:
        "We're a tech-based company made of enthusiastic nerds ready to challenge themselves.\nFrom NFT Games to DAOs, we're ready to build stunning project. Wanna join us? ",
    },
    manifesto: {
      discoverMore: 'Discover more...',
      pointOneCaps: 'What is our mission?',
      pointOne:
        'Not just random art: we create NFTs collections with actual utility, mostly in the form of NFT games, but also DAOs or other different projects',
      pointTwoCaps: 'Non Elitist Community',
      pointTwo:
        'We want to make our community open for everyone to join by untying it from the single projects',
      pointThreeCaps: 'Cutting Edge Technologies',
      pointThree:
        'Experimenting the cutting edge technologies in order to design the optimal solutions in terms of privacy, decentralization and gas-efficiency',
      pointFourCaps: 'Tansparency is a basic',
      pointFour:
        'No secrets between us and our users: we go open source whenever we can',
    },

    projects: {
      title: 'Our Projects',

      factoryWorkers: {
        title: 'Help the Factory grow!',
        description:
          'Support NFTF by minting your very own Factory Worker.\nYour trust will be repaid with exclusive privileges!',
        cta: 'Collect now',
      },

      aof: {
        title: 'Free the Factory!',
        description:
          'The Factory is under attack! The high levels of fun produced in this place have attracted fun-eating monsters from another dimension; they are going to steal all the entertainment from the world, leaving only sadness and boredom. Will you be able to stop the invasion?',
      },

      dolls: {
        title: 'Sugar, spice, and everything nice.',
        description:
          'These were the ingredients chosen to create the perfect little dolls.\nBut we accidentally added an extra ingredient to the brew: MetaMagic powder.',
        cta: 'Show more',
      },

      available: 'Available',
      soldOut: 'Sold Out',
      soon: 'Coming Soon',
    },

    manifestoProject: {
      title: "We're looking for buddies!",
      description:
        "We have loads of ideas we wish they came true, but we can't do this just by ourselves. Here's why we dropped Factory Workers: a way for you to support and get to know our team better. Grab one of these beauty and secure yourself a looot of advantages",
      cta: 'Become a supporter',
    },

    nftToPlay: {
      title: 'Gaming is not just about playing anymore',
      description:
        'Investing in NFTs games means you and your buddies can resell assets, designed to be interoperable across different environments. Moreover, In-game purchases can be bought and sold regardless of what happens to the game.',
      cta: 'Get in touch to know all the benefits!',
    },

    team: {
      title: 'Team',
    },

    partners: {
      title: 'Our Beloved Partners',
    },

    b2b: {
      title: "Wanna make your own NFTs?\nWe've got it covered!",
      description:
        'We offer consultancy services for companies who want to get into the NFTs game collections and discover their perks',

      card: {
        firstTitle: 'NFTs Design',
        firstDesc: 'We take care of your design and make it come true',
        secondTitle: 'Game Design',
        secondDesc:
          'We develop a game around your little creature by designing it and thinking about a strategy',
        thirdTitle: 'Game Development',
        thirdDesc:
          'We make your game come true, from the design to the development',
      },

      cta: 'Contact Us',
    },

    stayUpdated: {
      title: 'Stay Updated!',
      email: 'Insert your e-mail',
    },

    blog: {
      title: 'The Factory Blog',
      readMore: 'Read More',
      postDate: 'Posted on ',
    },

    newsLetter: {
      description:
        'Subscribe to our newsletter to stay tuned for the latest information regarding NFT Factory',
      error: 'Oops... Something went wrong. Can you retry please?',
    },

    factoryWorkerHeader: {
      title: 'What are Factory\nWorkers?',
      description:
        'A collection of 2000 NFTs living on the Ethereum blockchain.\nMinting a Factory Worker is the best way to support us and allows us to keep working on all the amazing projects we have in mind.\nIn exchange for your trust, the ownership of a token grants several benefits among our community.',
      disclaimer: 'The Factory is open,\nbut we need workers!',
      cta: 'Mint a Worker',
    },

    privileges: {
      title: 'Factory Worker',
      subtitle:
        'Collect a Factory Worker and get exclusive advantages while supporting us!',
      fw01: 'FW-001',
      skills: 'Skills',
      ama: {
        title: 'Special AMA',
        description:
          "We'll organize special Ask-Me-Anything sessions restricted to the holders, to answer the craziest questions of the community",
      },

      discount: {
        title: 'Factory Discounts',
        description:
          'Holders will receive special discounts on several upcoming collections by NFT Factory',
      },

      whitelist: {
        title: 'Lifetime Whitelist',
        description:
          'Holders will be whitelisted on all the NFT Factory collections with 10k or more items',
      },

      airdrop: {
        title: 'NFT Airdrop',
        description:
          'Holders will have the possibility to enter special airdrops from NFT Factory collections',
      },

      events: {
        title: 'Interactive Events',
        description:
          'Holders will be able to attend exclusive events organized by NFT Factory',
      },
    },

    mixGenes: {
      title: 'Mix your genes with ours',
      description:
        'Each Factory Worker is randomly-generated and unique.\nMinting a token is a concrete way to show us your support; we will always remember of people who first believed in us and in our project.\nWe need your help!',
      mutations: '2K Mutation of Us',
      variants: ' Variant of each character',
      golden: ' Golden Workers',
    },

    privilegesGold: {
      title: 'We still have some Gold oil...',
      subtitle:
        'If you are lucky enough you can mint a Golden Worker which grants special additional benefits!',
      fw01: 'FW-001',
      g: 'G',
      skills: 'Skills',
      allTheFW01: {
        title: 'All FW-001 Skills',
        description:
          'Golden Workers come with all the advantages granted by common Factory Workers',
      },
      selection: {
        title: 'Projects Selection',
        description:
          'Holders will have the possibility to vote a project from a list and to see it realized by NFT Factory',
      },
      proposal: {
        title: 'Propose your projects',
        description:
          'Holders will have the possibility to propose their projects to the NFT Factory Board and, upon approval, work on them together with our team!',
      },
      members: {
        title: 'Meet Factory Workers',
        description:
          'Holders will be able to meet their masked heros, the NFT Factory team!',
      },

      dropFrequency: 'Drop Frequency:',
      first200: 'First 200',
      three: ' 3%',
      last: '201 - 2k',
      oneAndHalf: '1,5%',
    },

    lastMinted: {
      title: 'Last Minted',
    },

    profile: {
      title: 'Your Factory NFTs',
    },

    faq: {
      title: 'FAQ',
      subtitle: 'Join Our Community',
      description:
        'Join NFT Factory Community to get in touch with the team and all the Factory Workers!',
      orWrite: 'or Write Us Now',
      nftFactory: 'NFT Factory',
      faq1: {
        title: "What's an NFT?",
        description:
          'NFT stands for “Non-Fungible Token” — a unique digital asset which can be bought, owned and traded. The scope of an NFT collection is defined by its creator; some just represent the ownership of pieces of digital art, others offer additional utility, like exclusive access to websites or IRL event.',
      },
      faq2: {
        title: 'What is Ethereum?',
        description:
          'Ethereum is a public permissionless blockchain whose native cryptocurrency is called Ether (ETH). Learn all the details at their website (https://ethereum.org/).',
      },
      faq3: {
        title: 'What is MetaMask?',
        description:
          'MetaMask is a cryptocurrency wallet that enables users to access the Web3 ecosystem and its decentralized applications. A wallet like this (not necessarily MetaMask) is needed to purchase and mint a Factory Worker; it gives you an Ethereum address (i.e. 0xABCD….1234) to receive your NFT. Learn more about MetaMask on their website (https://metamask.io/)',
      },
      faq4: {
        title: 'Where does my NFT go after I purchase a Factory Worker?',
        description:
          'Your Factory Worker will be sent to the wallet you used to purchase the NFT. You can see your freshly minted token on OpenSea, under your profile page.',
      },
      faq5: {
        title: 'This sounds cool. How can I get involved?',
        description:
          'A great place to start is our Discord, home to a very active community of NFT and gaming enthusiasts. You don’t need to own a Factory Worker to join us there! Everyone is welcome!',
      },
      faq6: {
        title: 'Are Factory Workers a good investment?',
        description:
          "Ultimately, this is a decision for you to make. We believe Factory Workers have a long life ahead of them, and will be an ever-growing and evolving project. However, as usual, the success of this collection relies on many factors and variables, thus we cannot be 100% sure everything will go as planned. We advise you to weigh carefully your purchases and don't spend money you can't afford to.",
      },
    },

    selectConnection: {
      title: 'How do you want to continue?',
    },

    mintModal: {
      title: 'Now you can Mint!',
    },

    mintedModal: {
      title: 'Congratulations!\nYou minted this Factory Worker:',
      errorTitle: 'Oopss.. Something went wrong',
      error: 'Unfortunately something went wrong.. Can you please retry?',
    },

    404: {
      title: 'Sorry, this page is beyond our knowledge',
      cta: 'Slap a dev',
    },
  },
}

export default en
