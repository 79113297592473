import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import I18NextHttpBackend from 'i18next-http-backend'
import en from '../lang/en'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: en,
}

i18n
  .use(I18NextHttpBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,

    load: 'languageOnly',

    ns: 'translations',
    defaultNS: 'translations',

    react: {
      useSuspense: false,
    },

    interpolation: {
      escapeValue: true, // react already safes from xss
    },
  })

export default i18n
